import {useState, useEffect} from 'react'
import useFetch from '../utils/useFetch';
import { api_url, normalize } from '../utils';
import { Link } from 'react-router-dom';
function Projects() {
  const [filter, setFilter] = useState('all');
  const [projects, setProjects] = useState([]);
  const { loading, error, data } = useFetch(api_url +'/api/projects?populate=*')

  //fetch categories
  const { loading: loadingCat, error: errorCat, data: dataCat } = useFetch(api_url +'/api/categories')
  let categories = []
  if(loadingCat){
    console.log('loading...')
  } else if (errorCat){
    console.warn('error')
  } else {
     categories = normalize(dataCat)
  }

  //normalize data
  const projectsData = normalize(data)
  // projects data
  useEffect(()=>{
    setProjects(projectsData)
  },[])

// render projects when filter is clicked
  useEffect(()=>{
    // setProjects([])
    const filtered = projects.map(item => ({...item, filtered: item.categories.includes(filter)}))
    setProjects(filtered)
  },[filter])

// fetching data error and loading
  if(loading){
    return <p>Loading...</p>
  } else if (error){
    return <p className="text-center py-11 text-2xl">This is kind of embarrassing, we are sorry about it. Please reload page</p>
  } 
// filter categories
  let results = projectsData.filter(function(project){
    if(filter === 'all') {
      return project
    } else {
      return project.categories[0].name === filter
    }
  })

  return (
    <>
      <div className="m-4 pt-4 index-projects max-sm:flex-wrap uppercase max-sm:text-xs ">
        <a href="/projects#" active={filter ? 'all': null} onClick={()=> setFilter('all')} className={`max-sm:flex ${filter === 'all' ? 'text-white font-bold rounded bg-blue-500 ':''}`}>all</a> 
        {categories.map((category) => (
          <li key={category.id} href="/projects#" active={filter ? category.name : null} onClick={()=> setFilter(category.name)} 
          className={`inline max-sm:flex p-2 mr-2 max-sm:p-2${filter === category.name ? ' text-white font-bold rounded bg-blue-500 ':''}`}>{category.name}</li>
        ))}
      </div>
    <div className='mx-2 m-4 max-sm:m-5 font-medium max-sm:text-xs'>
      <p className='text-gray-500'>Select a category above to filter through the projects. Click on the project below to view its contents.</p>
    </div>
    <div className="grid lg:grid-cols-6 gap-0 max-sm:grid-cols-2 md:grid-cols-3">
    {results.map((project) => (
      <div key={project.id} className="pt-0 hover:opacity-60 relative project-div">
         <Link to={`/projects/${project.id}/${project.slug}`}>
          <img src={`${api_url + project.thumbnail.formats.small.url}`} width="260px"   alt={project.thumbnail.alternativeText} />
          <h1 className="font-bold absolute text-xl uppercase text-white text-center insert-center">{project.title}</h1>
          </Link>
         <div> 
         </div>
      </div>
    ))}
    </div>
    </>
  );
}

export default Projects