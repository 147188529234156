import { useEffect, useState } from 'react'
import axios from 'axios'

function useFetch(url) {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading,setLoading]  = useState(true)
    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            try {
                const res = await axios.get(url)
                setData(res.data.data)
                setLoading(false)
            } catch (error) {
                setError(error)
                setLoading(false)
            }
        }
        getData()
     }, [url])
  return (
   {loading, error, data}
  )
}

export default useFetch