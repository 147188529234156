
import React from "react";
import PropTypes from 'prop-types'

function YoutubeEmbed({ embedId }) {
  return (
    <div className="mt-6 aspect-w-16 aspect-h-9">
      <iframe
        width="853"
        height="480"
        // className=" inset-0 w-full h-full"
        src={`https://www.youtube.com/embed/${embedId}`}
        // frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};
export default YoutubeEmbed;
