import useFetch from "../utils/useFetch";
import { api_url, normalize } from "../utils";
import { Link } from "react-router-dom";
function Home() {
  const { loading, error, data } = useFetch(
    api_url + "/api/projects?populate=*"
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-center py-11 text-2xl">This is kind of embarrassing :(  Please reload page</p>;
  const mydata = normalize(data);
  return (
    <>
      <div className="grid grid-cols-4 gap-4 max-sm:grid-cols-2 max-xs:grid-cols-2 max-xs:m-2 max-lg:grid-cols-3 2xl:grid-cols-4 2xl:m-4 max-lg:ml-2"    onContextMenu={(e) => {
        e.preventDefault();
      }}>
        {mydata.slice(0,8).map((project) => (
          <div key={project.id} className="pt-4 max-lg:pt-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
            <Link to={`/projects/${project.id}/${project.slug}`}>
              <img
                src={`${api_url + project.thumbnail.formats.small.url}`}
                width="300px"
                alt={project.thumbnail.alternativeText}
              />
            </Link>
            <h1 className="font-medium">{project.title}</h1>
            <p className="text-sm max-xs:hidden">
              {/* {project.description.substring(0, 40)}... */}
            </p>
            <Link
              className="text-sm text-blue-500"
              to={`/projects/${project.id}/${project.slug}`}
            >
              See more
            </Link>
          </div>
        ))}
      </div>
      <div className="py-6 grid place-items-center">
        {/* call to action button */}
        <Link to={`/projects`}>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded animate-bounce ">
          See More &darr;
          </button>
        </Link>
      </div>
    </>
  );
}

export default Home;
