import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../utils/useFetch";
import { api_url, normalize } from "../utils/index";
import { useEffect, useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import YoutubeEmbed from "../components/Youtube";
import axios from "axios";
function ProjectDetails() {
  const [album, setAlbum] = useState([]);
  //for the image viewer
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  //id of the project
  const { id } = useParams();
  const { loading, error, data } = useFetch(
    `${api_url}/api/projects/${id}?populate=*`
  );
  // clean this section up
  let cleanData = normalize(data);
  // console.log(cleanData)

  //strapi fetch data with relation to another table
  const getData = async () => {
    //get the correct album
    try {
      const res = await axios.get(
        `${api_url}/api/albums/${id}?populate=photos`
      );
      const newData = normalize(res.data.data);
      setAlbum(newData);
    } catch (error) {
      console.log(error);
    }
  };
  //only fetch data once when the component is mounted
  useEffect(() => {
    getData();
  }, []);

  //open imageviewr
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  //close imageviewer
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <p className="text-center py-11 text-2xl">
        This is kind of embarrassing, We are sorry about it. Please reload page
      </p>
    );

  return (
    <div
      className="container mx-auto max-sm:px-4 max-lg:px-5 px-10"
      // console.log("Right Click");
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <h1 className="text-2xl my-7">{data.attributes.title}</h1>
      {/* <p className="my-4">{data.attributes.description}</p> */}
      <div>
        <div
          className={
            "grid gap-2 " +
            (cleanData.categories[0].name === "video"
              ? "grid-cols-1"
              : "grid-cols-4 max-w-4xl")
            //`grid-cols-${Math.floor(album.photos.length / 2)}`
          }
        >
          {/* // check to see it is video, get embed [album.embed]  */}
          {cleanData.categories[0].name === "video" ? (
            <YoutubeEmbed embedId={cleanData.album.embed} />
          ) : (
            // loop through the photos if not video && sort by height
            album.photos &&
            album.photos
              .sort((a, b) => (a.height < b.height ? 1 : -1))
              .map((src, index) => (
                <img
                  src={`${api_url + src.formats.small.url}`}
                  onClick={() => openImageViewer(index)}
                  // width="100vw"
                  className={
                    " w-full h-full object-cover hover:opacity-40 cursor-pointer"
                  }
                  key={index}
                  style={{ marginBottom: "1px" }}
                  alt={src.caption}
                />
              ))
          )}
          {/* image viewer */}
          {isViewerOpen && (
            <ImageViewer
              // FIXME: sort like above, thumbnails are not in order
              src={album.photos
                .sort((a, b) => (a.height < b.height ? 1 : -1))
                .map((src) => `${api_url + src.url}`)}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              backgroundStyle={{ backgroundColor: "rgba(255, 255, 255, 09)" }}
            />
          )}
        </div>
        {cleanData.categories[0].name === "video" ? null : (
          <p className="text-gray-400 text-[12px] text-center italic mt-5">
            Click image to open the slider. Note: Photos displayed on this site
            are deliberately of low resolution. If you want to see the high
            quality versions contact me!
          </p>
        )}
      </div>
    </div>
  );
}

export default ProjectDetails;
