import './App.css';
import Header from './components/Header'
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import Footer from './components/Footer';
import LogRocket from 'logrocket';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  LogRocket.init('4omnso/shoots-hpff3');
  return (
    <div className="App container mx-auto">
      <Router>
        <Header />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/projects/:id/:slug' element={<ProjectDetails />} />
        </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
