import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { api_url } from "../utils/index";
function Contact() {
  const [showAlert, setShowAlert] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      city: "",
      subject: "",
      message: "",
    },
  });

  // const onSubmit = data => console.log(data, errors);
  const onSubmit = async (data) => {
    //clean the data
    JSON.stringify(data);
    let res = await axios
      .post(api_url + "/api/responses/", { data: data })
      .then(reset(), setShowAlert(true))
      .catch(
        // (err) => console.log(err),
        function (err) {
          if (err) {
            console.log(err, "here");
            //show success msg
            setShowAlert(false);
          }
        }
      );
  };

  return (
    <div>
      <div className="">
        <div className="md:px-20 px-4 py-8">
          <div className="flex items-center justify-between">
          </div>
          <p className="my-5 text-center">
            Fill in the form below to get in touch with me about information regarding
            creative collaborations, hiring me, exhibition opportunities, editorial and
            licensing of photographs.
          </p>
        </div>
        <div className="w-full flex items-center justify-center my-4 md:pb-10">
          <div className="top-40 bg-white shadow rounded py-12 lg:px-28 px-8">
            <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">
              Get in touch with me today!
            </p>
            {/* alert */}
            {showAlert ? (
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mt-4 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Holy smokes! </strong>
                <span className="block sm:inline">
                  We'll get back to shortly.
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg
                    onClick={() => setShowAlert(null)}
                    className="fill-current h-6 w-6 text-red-500"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </span>
              </div>
            ) : null}
            {/* alert */}

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="md:flex items-center mt-12">
                <div className="md:w-72 flex flex-col">
                  <label className="text-base font-semibold leading-none text-gray-800">
                    Name
                  </label>
                  <input
                    {...register("name", { required: "Enter your full name" })}
                    defaultValue="Name"
                    tabIndex={0}
                    arial-label="Please input name"
                    type="name"
                    className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                    placeholder="Please input  name"
                  />
                  <p className="text-red-400">{errors.name?.message}</p>
                </div>
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-gray-800">
                    Email Address
                  </label>
                  <input
                    {...register("email", {
                      required: "Provide an a valid email",
                    })}
                    tabIndex={0}
                    arial-label="Please input email address"
                    type="name"
                    className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                    placeholder="Please input email address"
                  />
                  <p className="text-red-400">{errors.email?.message}</p>
                </div>
              </div>
              <div className="md:flex items-center mt-8">
                <div className="md:w-72 flex flex-col">
                  <label className="text-base font-semibold leading-none text-gray-800">
                    City
                  </label>
                  <input
                    {...register("city")}
                    tabIndex={0}
                    role="input"
                    arial-label="Please input city"
                    type="text"
                    className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 "
                    placeholder="Please input city"
                  />
                </div>
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-gray-800">
                    Subject
                  </label>
                  <input
                    {...register("subject", { required: "required field" })}
                    tabIndex={0}
                    arial-label="Please input subject name"
                    type="text"
                    className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                    placeholder="Please input subject"
                  />
                  <p className="text-red-400">{errors.subject?.message}</p>
                </div>
              </div>
              <div>
                <div className="w-full flex flex-col mt-8">
                  <label className="text-base font-semibold leading-none text-gray-800">
                    Message
                  </label>
                  <textarea
                    {...register("message", {
                      required: "You can't leave this blank",
                      minLength: {
                        value: 30,
                        message: "minimum character is 30",
                      },
                    })}
                    tabIndex={0}
                    aria-label="leave a message"
                    type="name"
                    className="h-36 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 resize-none"
                  />
                  <p className="text-red-400">{errors.message?.message}</p>
                </div>
              </div>
              <p className="text-xs leading-3 text-gray-600 mt-4">
                By clicking submit you agree to our terms of service, privacy
                policy and how we use data as stated
              </p>
              <div className="flex items-center justify-center w-full">
                <button className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-blue-500 rounded hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:outline-none">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
