import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { useState } from "react";
import logo from "../imgs/logo.png";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="">
      <div className="shadow px-3 mt-4 grid grid-cols-2 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-2">
        {/* header logo */}
        <div className="h-10 w-10 xs:mt-2 rounded-full bg-white/90 p-0.5 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:ring-white/10 lg:ml-8">
          <Link aria-label="Home" className="pointer-events-auto" to="/">
            <img
              alt="Hermeyshoots"
              src={logo}
              width="512"
              height="512"
              className="h-9 w-9 rounded-full bg-zinc-100 object-cover"
            />
          </Link>
        </div>

        {/* navigation menu */}
        <nav className=" m-3 ml-40 hidden md:block lg:text-lg ">
          <ul className="flex ml-4">
            <Link to="/">
              <li className="list-none mr-6 lg:mr-8 hover:text-blue-500">
                Home
              </li>
            </Link>
            <Link to="/about">
              <li className="list-none mr-6 lg:mr-8 hover:text-blue-500">
                About
              </li>
            </Link>
            <Link to="/projects">
              <li className="list-none mr-6 lg:mr-8 hover:text-blue-500">
                Projects
              </li>
            </Link>
            <Link to="/contact">
              <li className="list-none mr-6 lg:mr-8 hover:text-blue-500">
                Contact
              </li>
            </Link>
            <a href="https://www.instagram.com/hermeyshoots" target="_blank" className="pointer-events-auto lg:ml-12" rel="noreferrer">
              <button type="button" className="rounded-md">
                <FaInstagram className="text-2xl" />
              </button>
            </a>
          </ul>
        </nav>
        {/* mobile menu button */}
        <div className="flex flex-1 justify-end md:justify-center m-2 xs:-z-50 sm:z-5 ">
          <div className="pointer-events-auto md:hidden">
            <button
              className="group flex items-center rounded-full bg-white/90 px-4 py-2 text-sm font-medium  dark:bg-gray-800/90 dark:text-zinc-200 dark:hover:ring-white/20"
              type="button"
              aria-expanded="false"
              onClick={() => setShowMenu(true)}
            >
              Menu
              <svg
                viewBox="0 0 8 6"
                aria-hidden="true"
                className="ml-3 h-auto w-2 stroke-zinc-500 group-hover:stroke-zinc-700 dark:group-hover:stroke-zinc-400"
              >
                <path
                  d="M1.75 1.75 4 4.25l2.25-2.5"
                  fill="none"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        {/* menu button ends here..menu below */}

        {showMenu && (
          <>
            <div
              className="fixed inset-0 z-50 bg-zinc-800/40 opacity-100 backdrop-blur-sm dark:bg-black/80"
              aria-hidden="true"
            ></div>
            <div
              className="fixed inset-x-4 top-8 z-50 origin-top scale-100 rounded-3xl bg-white p-8 opacity-100 ring-1 ring-zinc-900/5 dark:bg-white-900 dark:ring-zinc-800"
              tabIndex="-1"
              onClick={() => setShowMenu(false)}  // close menu on click outside
            >
              <div className="flex flex-row-reverse items-center justify-between">
                <button
                  aria-label="Close menu"
                  className="-m-1 p-1"
                  type="button"
                  tabIndex="0"
                >
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    className="h-6 w-6 text-zinc-500 dark:text-zinc-400"
                  >
                    <path
                      d="m17.25 6.75-10.5 10.5M6.75 6.75l10.5 10.5"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
                <h2 className="text-sm font-medium text-zinc-600">
                  Navigation..
                </h2>
              </div>
              <nav className="mt-6">
                <ul className="-my-2 divide-y divide-zinc-100 text-base text-zinc-800">
                  <Link to="/">
                    <li className="block py-2">Home</li>
                  </Link>
                  <Link to="/about">
                    <li className="block py-2">About</li>
                  </Link>
                  <Link to="/projects">
                    <li className="block py-2">Projects</li>
                  </Link>
                  <Link to="/contact">
                    <li className="block py-2">Contact</li>
                  </Link>
                  <a href="https://www.instagram.com/hermeyshoots" target="_blank" className="pointer-events-auto lg:ml-12" rel="noreferrer">
              <button type="button" className="rounded-md block">
                <FaInstagram className="text-3xl bg-orange-500 rounded-lg" /> 
              </button>
            </a>
                </ul>
              </nav>
            </div>
          </>
        )}
      </div>
    </header>
  );
}
